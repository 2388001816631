import React from 'react';
const {graphql, useStaticQuery} = require("gatsby");

export const useContentfulImage = (assetUrl) => {
    const { assets } = useStaticQuery(
        graphql`
            query CONTENTFUL_IMAGE_QUERY {
                assets: allContentfulAsset {
                    edges {
                        node {
                            contentful_id
                            title
                            gatsbyImageData
                        }
                    }
                }
            }
        `
    )
    const asset = assets.edges.find(({ node }) => node.contentful_id === assetUrl)
    return asset
}
