import * as React from "react"
import { graphql } from "gatsby"
import { Container, Box, Kicker, Heading, Text } from "./ui"
import Document from "./document";
import * as styles from "./document-list.css";


export default function DocumentList(props) {
  return (
    <Container width="fullbleed">
      <Box background="black" radius="large">
        <Box center paddingY={5} className={styles.documentListBox}>
          <Heading>
            {props.kicker && <Kicker>{props.kicker}</Kicker>}
            {props.heading}
          </Heading>
          {props.text && <Text>{props.text}</Text>}
        </Box>
        {props.content.map((feature, i) => (
          <Document key={feature.id} {...feature} flip={Boolean(i % 2)} />
        ))}
      </Box>
    </Container>
  )
}
