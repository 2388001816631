import * as React from "react"
import { graphql } from "gatsby"
import {StaticImage} from "gatsby-plugin-image"
import {
  Container,
  Section,
  Flex,
  Box,
  Subhead,
  Kicker,
  Text,
  ButtonList, Button,
} from "./ui"
import * as styles from "./document-list.css";

export default function Document(props) {
  return (
    <Section padding={0} background="muted" className={styles.documentSection} >
      <Container>
        <Flex gap={2} variant="responsive">
          <Box padding={0}  width="quarter" order={props.flip ? 1 : null} >
              <StaticImage
                alt={"PDF document"}
                src="../pdf-icon.png"
                placeholder="blurred"
                layout="fixed"
                width={50}
                height={50}
                className={styles.documentIcon}
              />
          </Box>
          <Box background="black"  width="quarter" className={styles.documentTitle}>
            <Subhead style={{marginBottom: 0}}>
              {props.alt}
            </Subhead>
          </Box>
          <Box background="black"  width="quarter" className={styles.documentButton}><Button target="_blank" href={props.url}>Download</Button></Box>
        </Flex>
      </Container>
    </Section>
  )
}

export const query = graphql`
  fragment HomepageFeatureContent on HomepageFeature {
    id
    kicker
    heading
    text
    links {
      id
      href
      text
    }
    image {
      id
      gatsbyImageData
      alt
    }
  }
`
