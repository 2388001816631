import * as React from "react"
import {graphql, useStaticQuery} from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import Slider from "react-slick";
import SimpleReactLightbox, {SRLWrapper} from 'simple-react-lightbox'
import Layout from "../../components/layout"
import {Container, Box, Heading, Flex} from "../../components/ui"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import AboutHero from "../../components/about-hero";
import DocumentList from "../../components/document-list";
import HomepageCta from "../../components/cta";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {documentToHtmlString} from "@contentful/rich-text-html-renderer";
import {useContentfulImage} from "../../components/util";
import * as styles from "../../components/about-hero.css";


export default function ProductPage(props) {
    const {productPage} = props.data
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };


    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return(<p className={styles.aboutHeroHtml}
                                                            dangerouslySetInnerHTML={{
                                                                __html: documentToHtmlString(node),
                                                            }}
            />)},
            [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
                // const assets = useContentfulImage(node.data.target.sys.id)
                return (<div>
                    {/*<GatsbyImage image={getImage(assets.node.gatsbyImageData)} alt={assets.node.title}/>*/}
                    </div>
                )
            }
        }
    }
    const description = documentToReactComponents(productPage.description, options)

    return (
        <SimpleReactLightbox>
            <Layout {...productPage}>
                <Container width="fullbleed">
                    <Box paddingY={5} background="black" radius="large">
                        <Container width="narrow">
                            <Heading style={{textAlign: 'right', marginBottom: '30px'}} as="h1">{productPage.title}</Heading>
                            <Flex gap={6} variant="responsive">
                                <Box width="twothirds">
                                    <SRLWrapper>
                                        <Slider {...settings}>
                                            {productPage.images.map((image, index) => (
                                                <GatsbyImage
                                                    key={image.id}
                                                    alt="Product image"
                                                    image={getImage(image.gatsbyImageData)}
                                                />
                                            ))}
                                        </Slider>
                                    </SRLWrapper>
                                </Box>
                                <Box width="fitContent" center>
                                    <div>{productPage.text}</div>
                                    <div>SKU: {productPage.sku}</div>
                                    <div>Category: {productPage.category}</div>
                                </Box>
                            </Flex>
                        </Container>
                    </Box>
                    <Box paddingY={5}>
                        <Container width="narrow">
                            <AboutHero
                                heading="Description"
                            >{description}</AboutHero>
                            <AboutHero
                                heading="Technical Information"
                                html={productPage.technicalInformation}
                            />
                            {productPage?.documents?.length > 0 && (
                                <DocumentList
                                    heading={"Documents"}
                                    kicker={"DOWNLOADS"}
                                    content={productPage.documents}
                                />
                            )}
                        </Container>
                    </Box>
                    <HomepageCta
                        kicker="SEE OUR PRODUCTS"
                        heading="Feel the richness of the harmonies"
                        text="What a pair of beasts these TLA amps are–and they’ll play louder and go deeper than just about any other high-end audio amplifier..."
                        links={[{
                            href: "/#products",
                            text: "Our products"
                        },
                            {
                                href: "/reviews",
                                text: "Our reviews"
                            }]}
                    />
                </Container>
            </Layout>
        </SimpleReactLightbox>
    )
}

export const query = graphql`
    query ProductPageContent($title: String!) {
        productPage(title: { eq: $title }) {
            id
            title
            text
            sku
            category
            description
            technicalInformation
            images {
                id
                url
                gatsbyImageData
            }
            documents {
                id
                url
                alt
            }
        }
    }
`
